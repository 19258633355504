import { currentVersion } from './../app/shared/version-number/version';
export const environment = {
  production: false,
  baseUrl: window.location.origin + '/api/radial',
  rucUrl: window.location.origin + '/api/ruc',
  pingUrl: window.location.origin + '/api/ping/userinfo',
  generateReportUrl:
    window.location.origin +
    '/generatereport/cpyn5fak3mqn7avitwbrcpxsqe0diyob.lambda-url.eu-west-1.on.aws',
  qlikViewGraphUrl:
    window.location.origin + '/api/radial/clientbaselinereports',
  cbrGraphUrl: window.location.origin + '/api/radial/clientbaselinereports',
  clientConfigUrl: window.location.origin + '/api/radial/clientconfig/',
  userConfigUrl: window.location.origin + '/api/radial/userconfig/',
  logout: 'https://login.bpost.be/idp/startSLO.ping',
  VERSION: currentVersion,
  googleAnalyticsMeasurementId: 'G-4G1VE1QGMY',
  reportUrl: 'https://datawarehouse-report.s3.eu-west-1.amazonaws.com/',
  rootUrl: window.location.origin,
  DEPLOYED_BRANCH_NAME: 'feature/st(5a41037094fd15bcc71f849fddcd3be78a0caa36)',
  DEPLOYED_BRANCH_URL: 'https://bitbucket.org/bpost_deliveryparcelsint/rcp-angular-js-frontend/commits/5a41037094fd15bcc71f849fddcd3be78a0caa36',
};
